.navbar {
  position: absolute;
  display: flex;
  background-color: #eb1c24;
  top: 0;
  width: 100%;
  padding: 0px;
  justify-content: space-between;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
}

.navbar-button {
  margin: 10px 10px;
  float: left;
  padding: 12px 20px;
  font-size: 20px;
  background: #fff;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.logo {
  width: 100px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
}

/* .navbar button:hover {
  transform: translateY(-0.2em);
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25));
} */
