.control-button {
  float: right;
  margin-right: 5px;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  background: #eb1c24;
  color: white;
}

.control-button:hover {
  background-color: #d81d23;
}

.control-button:disabled {
  background: #ed767a;
}

.control-icon {
  margin-right: 5px;
}
