* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.signin-card {
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 70px;
  left: calc(50% - 200px);
  width: 400px;
  height: 350px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 4px 0px 60px 4px rgba(0, 0, 0, 0.15);
  padding: 10px 20px 10px 20px;
}

.logo {
  width: 45%;
  height: 20%;
  margin-bottom: 5px;
}

.signin-form {
  padding: 10px;
  height: 60%;
  margin-top: -15px;
}

.textfield {
  margin: 8px 0px;
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  display: inline-block;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.textfield:focus {
  outline: none;
}

.login-button {
  width: 100%;
  margin: 30px 0px 10px;
  border: none;
  background-color: #eb1c24;
  border-radius: 30px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.forgot-pass {
  position: absolute;
  top: 86%;
  left: calc(50% - 55px);
  background: none;
  border: none;
  padding: 0;
  color: #eb1c24;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-pass:hover {
  color: #a11419;
}

.login-button:hover {
  background-color: #d81d23;
}

.error-message {
  margin: 0px 10px;
  height: 10px;
  font-size: 11px;
  color: #eb1c24;
}

.reset-pass-header {
  color: black;
  margin: 0px 0px 0px 0px;
  font-size: 16px;
}

.reset-pass-header-white {
  color: white;
  margin: 0px 0px 0px 0px;
  font-size: 16px;
}

@media only screen and (max-width: 400px) {
  .signin-card {
    width: 300px;
    height: 300px;
  }

  .signin-form {
    padding: 10px 5px;
  }

  .logo {
    width: 50%;
    height: 18%;
  }

  .textfield {
    margin: 8px 0px;
    height: 30px;
  }

  .reset-pass-header {
    font-size: 18px;
  }
}
