.dashboard-card-inner {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  border-radius: 30px;
  width: 100%;
  padding: 15px;
  overflow: hidden;
}

.dashboard-controls-container {
  box-sizing: border-box;
  height: 5%;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  padding-top: 5px;
  box-shadow: "0px 0px 5px rgba(0, 0, 0, 0.15)";
  border-radius: 15px;
}

.dashboard-controls {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-charts-container {
  width: 100%;
}
