.users-card {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  border-radius: 30px;
  width: 100%;
  padding: 20px;
}

.user-controls {
  border-radius: 30px;
  width: 100%;
}

.users-table-container {
  box-sizing: border-box;
  display: flex;
  height: 90%;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  background-color: white;
}
